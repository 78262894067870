
	import { defineComponent, ref, onMounted } from "vue";
	import { ErrorMessage, Form } from "vee-validate";
	import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
	import Swal from "sweetalert2/dist/sweetalert2.min.js";
	import * as Yup from "yup";

	import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";

	export default defineComponent({
		name: "healthsheet1new",
		components: {
			Form,
			ElCurrencyInputNoSymbol,
		},
		async mounted() {
			this.init();
			this.totalMonths = this.months.length;
			this.initDays();
		},
		data() {
			const schema = Yup.object({});

			const months = [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			];
			const totalMonths = 0;

			const statements = {
				incomeStatement: {
					label: "Income Statement",
					child: {
						totalSales: {
							label: "(1) Total Sales",
							value: [],
						},
						totalCostOfSales: {
							label: "(2) Total Cost of Sales",
							value: [],
						},
						grossFit: {
							label: "(3) Gross Profit",
							value: [],
						},
						totalOperatingExpenses: {
							label: "(4) Total Operating Expenses",
							value: [],
						},
						totalInterestCharges: {
							label: "(5) Total Interest Charges",
							value: [],
						},
						profitBeforeInterestAndTax: {
							label: "(6) Profit Before Interest and Tax",
							value: [],
						},
						profitAfterTax: {
							label: "(7) Profit After Tax",
							value: [],
						},
						numberOfDaysOfSales: {
							label: "(8) Number of Days of Sales",
							value: [],
						},
					},
				},
				financialPosition: {
					label: "Statement of Financial Position",
					child: {
						totalAssets: {
							label: "(1) Total Assets",
							value: [],
						},
						totalCurrentAssets: {
							label: "(2) Total Current Assets",
							value: [],
						},
						totalCurrentLiabilies: {
							label: "(3) Total Current Liabilities",
							value: [],
						},
						totalClosingStocks: {
							label: "(4) Total Closing Stocks",
							value: [],
						},
						totalDebtors: {
							label: "(5) Total Debtors",
							value: [],
						},
						totalCreditors: {
							label: "(6) Total Creditors",
							value: [],
						},
						totalShareholderEquity: {
							label: "(7) Total Shareholder Equity",
							value: [],
						},
						totalDebtHolding: {
							label: "(8) Total Debt Holding (e.g Bank loans)",
							value: [],
						},
					},
				},
			};
			return {
				months,
				totalMonths,
				statements,
			};
		},
		setup() {
			return {};
		},
		methods: {
			init() {
				setCurrentPageBreadcrumbs("Data from P&L or BS", ["Financial Statement"]);
			},
			onSubmit(value) {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        console.log(value);
        // window.location.reload();
      });
    },
			initDays() {
				for (let key of Object.keys(this.statements.incomeStatement.child)) {
					let incomeStatementChild = this.statements.incomeStatement.child;
					for (let index = 0; index < this.months.length; index++) {
						incomeStatementChild[key].value.push(0);
					}
				}

				for (let key of Object.keys(this.statements.financialPosition.child)) {
					let financialPositionChild = this.statements.financialPosition.child;
					for (let index = 0; index < this.months.length; index++) {
						financialPositionChild[key].value.push(0);
					}
				}
			},
		},
	});
